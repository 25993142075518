import stepView from 'app/components/availability/step/step.view.html';

export default {
    templateUrl: stepView,
    controller: 'StepController',
    controllerAs: 'stepVm',
    bindings: {
        options: '<',
        step: '=',
        selected: '=',
        channels: '<',
        shouldUseUbereats: '<',
    },
};

export default function (menu, channels, $state, Category, orderService, shouldUseUberEatsAvailability) {
    'ngInject';

    const vm = this;
    vm.menu = menu;
    vm.selectedRow = null;

    if ($state.is('home.availability.menu') && menu.categories.length > 0) {
        vm.selectedRow = null;
        vm.isLoading = true;
        vm.networkError = false;
        vm.shouldUseUberEatsAvailability = shouldUseUberEatsAvailability;
        loadAllProducts();
        // initState(0, menu.categories[0].categoryId, 0);
    }

    vm.loadCategory = function (categoryId, index) {
        initSelectedCategory(0, categoryId, index);
    };

    // eslint-disable-next-line consistent-return
    vm.getParentName = function (parentId) {
        for (let i = menu.categories.length; i >= 0; i--) {
            // eslint-disable-next-line eqeqeq
            if (menu.categories[i] != undefined && menu.categories[i].categoryId === parentId) {
                return menu.categories[i].name;
            }
        }
    };
    vm.loadAllProducts = loadAllProducts;

    function loadAllProducts() {
        Category.query({
            menuId: menu.menuId,
            should_fetch_ubereats_status: shouldUseUberEatsAvailability ? 1 : 0,
        }).$promise.then(
            function (data) {
                vm.isLoading = false;
                vm.networkError = false;
                vm.selectedRow = null;

                const mergedProducts = getMergedProductsForAllProductsCategory(data);
                const allProductsCategory = createAllProductsCategory(mergedProducts);
                vm.category = allProductsCategory;

                $state.go('home.availability.menu.category', { categoryId: null, category: allProductsCategory });
            },
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            function (error) {
                vm.isLoading = true;
                vm.networkError = true;
            },
        );
    }

    function initSelectedCategory(i, categoryId, index) {
        Category.get({
            menuId: menu.menuId,
            categoryId,
            restaurant_id: orderService.configReception.restaurantId,
            should_fetch_ubereats_status: shouldUseUberEatsAvailability ? 1 : 0,
        }).$promise.then(
            function (category) {
                vm.isLoading = false;
                vm.networkError = false;
                vm.category = category;
                vm.selectedRow = index;
                $state.go('home.availability.menu.category', {
                    categoryId,
                    category,
                });
            },
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            function (error) {
                vm.isLoading = true;
                vm.networkError = true;
                // eslint-disable-next-line no-undef, no-restricted-properties
                $timeout(initSelectedCategory, Math.pow(2, (i + 1) % 5) * 1000, true, i + 1, categoryId, index);
            },
        );
    }
}

export const getMergedProductsForAllProductsCategory = (data) => {
    const products = data.flatMap((category) => category.products);
    const mappedProductsWithCategoryNames = products.map((product) => {
        product.categoryNames = [
            data.find((category) => category.categoryId === product.category_product.categoryId).name,
        ];
        return product;
    });
    const productsWithoutDuplicate = Object.values(
        mappedProductsWithCategoryNames.reduce((acc, product) => {
            const { productId, ...rest } = product;
            if (!acc[productId]) {
                acc[productId] = { ...rest, categoryNames: product.categoryNames, productId };
            } else {
                acc[productId].categoryNames.push(product.categoryNames[0]);
            }
            return acc;
        }, {}),
    );

    return productsWithoutDuplicate;
};

export const createAllProductsCategory = (mergedProducts) => {
    return {
        categoryId: null,
        imageId: null,
        parentCategoryId: null,
        posLocalId: null,
        name: 'All products',
        position: 0,
        menuId: 1300,
        kioskAvailability: false,
        webAvailability: false,
        subCategory: [],
        products: mergedProducts,
    };
};

import productView from 'app/components/availability/product/product.view.html';

export default {
    templateUrl: productView,
    controller: 'ProductController',
    controllerAs: 'productVm',
    bindings: {
        product: '=',
        options: '<',
        selected: '=',
        channels: '<',
        update: '<',
        shouldUseUbereats: '<',
    },
};

import categoryView from 'app/components/availability/category/category.view.html';
import { getMergedProductsForAllProductsCategory, createAllProductsCategory } from './menu.controller';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider.state('home.availability.menu.category', states.category());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    category() {
        return {
            url: '/category/:categoryId',
            templateUrl: categoryView,
            controller: 'CategoryController',
            controllerAs: 'categoryVm',
            params: {
                categoryId: null,
                category: null,
            },
            resolve: {
                // eslint-disable-next-line consistent-return
                index(menu, $stateParams, $q) {
                    'ngInject';

                    if ($stateParams.categoryId) {
                        for (let i = 0; i < menu.categories.length; i++) {
                            // eslint-disable-next-line eqeqeq
                            if (menu.categories[i].categoryId == $stateParams.categoryId) {
                                return $q.resolve(i);
                            }
                        }
                    }
                },
                category($stateParams, menu, index, $q, Category, launchdarklyService, shouldUseUberEatsAvailability) {
                    'ngInject';

                    if ($stateParams.categoryId === null && $stateParams.category) {
                        return $stateParams.category;
                    }
                    if ($stateParams.category) {
                        return $stateParams.category.$promise;
                    }

                    const deferred = $q.defer();

                    Category.query({
                        menuId: menu.menuId,
                        should_fetch_ubereats_status: shouldUseUberEatsAvailability ? 1 : 0,
                    }).$promise.then(function (data) {
                        const mergedProducts = getMergedProductsForAllProductsCategory(data);
                        const allProductsCategory = createAllProductsCategory(mergedProducts);
                        deferred.resolve(allProductsCategory);
                    });

                    return deferred.promise;
                },
            },
        };
    },
};

export default function (statePromise, $stateParams) {
    'ngInject';

    const vm = this;

    vm.skuWarningData = $stateParams.skuWarningData;
    vm.marketplacesTabs = [{ id: 9, name: 'Ubereats', errorsAttribute: 'invalidUbereatsProducts' }];
    vm.selectedMarketplaceTab = null;
    vm.allWarnings = [];

    const setDisplayedWarnings = function (marketplaceTab) {
        const selectedMarketplaceWarnings = vm.skuWarningData[marketplaceTab.errorsAttribute];
        vm.allWarnings = [...selectedMarketplaceWarnings.noSku, ...selectedMarketplaceWarnings.noMatchingSku];
    };

    vm.$onInit = function () {
        const [initialMarketplace] = vm.marketplacesTabs;
        vm.selectedMarketplaceTab = initialMarketplace;
        setDisplayedWarnings(vm.selectedMarketplaceTab);
    };

    vm.onTabChange = function (marketplaceTab) {
        vm.selectedMarketplaceTab = marketplaceTab;
        setDisplayedWarnings(marketplaceTab);
    };

    vm.close = function () {
        statePromise.reject('skuWarning');
    };
}
